.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 200vh;
  display: flex;
  min-width: fit-content;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button{
  border-radius: 5px;
  padding:5px;
  padding-inline: 15px;
  border: solid black 2px;
  box-shadow: 0 0 15px black;
  cursor: pointer;
  color: white;
}
.button:active{
  transform: scale(0.99);
}
.button.red{
  background-color: red;
}
.button.green{
  background-color: green;
}
.button.blue{
  background-color: blue;
}
.button.disabled{
  background-color: gray;
}

.t-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px; /* adjust width as needed */
  height: 120px;
}

.triangle {
  width: 127px;
  height: 34px;
  padding-top: 30px;
  /* background-color:transparent; */
  position:absolute;
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(5,15px);
  justify-content: center;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.triangle-1 {
  left:-32px;
  top:32px;
  background-color: rgb(249, 91, 91);
  transform: rotate(90deg);
  
}

.triangle-2 {
  transform: rotate(180deg);
  background-color: skyblue;
  left: -1px;
}

.triangle-3 {
  transform: rotate(270deg);
    background-color: rgb(107, 243, 107);
    right: -30px;
    top: 33px;
}

.triangle-4 {
  transform: rotate(0deg);
    background-color: rgb(243, 243, 116);
    bottom: 0px;
    left: -2px;
}

#shake{
  animation: shake 2s  infinite ;
  /* animation-duration: 0.5s;
  animation-iteration-count: infinite; */
}

@keyframes shake {

  0%{
    transform: scale(1);
    z-index: 550;
  }
  25%{
    transform: scale(1.25);
    z-index: 550;
  }
  50%{
    transform: scale(1);
    z-index: 550;
  }
  75%{
    transform: scale(1.25);
    z-index: 550;
  }
  100%{
    transform: scale(1);
    z-index: 550;
  }

  
}

.playerName{
  position: absolute;
  width: 100%;
}

.housesquare{
  border:solid black 1px;
}
#active.housesquare{
   --border-size: 3px;
  --border-angle: 0turn;
  background-image: conic-gradient(
      from var(--border-angle),
      #213,
      #112 50%,
      #213
    ),
    conic-gradient(from var(--border-angle), transparent 20%, #08f, #f03);
  background-size: calc(100% - (var(--border-size) * 2))
      calc(100% - (var(--border-size) * 2)),
    cover;
  background-position: center center;
  background-repeat: no-repeat;
  animation: bg-spin 3s linear infinite;
}

@keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}
